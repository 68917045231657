<!--
    IMPORTANT NOTE: 

    IF YOU HAVE CHANGES IN THIS FILE, PLEASE VERIFY THE CHANGES BECAUSE THIS FILE IS CONNECTED TO THE PAYMENT MODULE.
    AND YOUR CHANGES ARE REQUIRED. ALSO ADD YOUR CHANGES TO THE 'CHARGEBEE-SETUP' AND 'PADDLE-SETUP' FOLDER.
-->
<template>
    <div class="d-flex align-items-center justify-content-between px-1 bg-blue white header__wrapper border-bottom-lightgray">
        <div class="d-flex align-items-center h-100">
            <img :src="toggle" alt="options" class="pr-1 cursor-pointer" v-if="clientWidth <= 990" @click="visible = !visible">
            <router-link to="/" class="d-flex align-items-center h-100">
                <img :src="headerLogo" class="cursor-pointer" />
                <img class="cursor-pointer m0px-20px" v-if="clientWidth > 990" src="@/assets/images/dashboard_icon.png" alt="">
            </router-link>

            <NavLinks v-if="clientWidth > 990 && rules && Object.keys(rules).length" :menu="menu" />
        </div>
        <small v-if="!showArchivedProjects && clientWidth >= 767" title="Open Advance Filter" class="project-list-title-small cursor-pointer" @click="dispatchEventForFilet">
            <template v-if="clientWidth >= 1150">
                <b >Ctrl + K</b><small class="project-list-title-small-text"> for Advance Search</small>
            </template>
            <template v-else>
                <b class="project-list-title-small-text">Advance Search</b>
            </template>
        </small>
        <div class="d-flex align-items-center justify-content-between z-index-7" v-if="clientWidth > 990">
            <!-- Upgrade Button -->
            
            <!-- COMPANY SELECTION -->
            <div class="d-flex" id="company_dropdown_driver">
            <span v-if="rules && Object.keys(rules).length" class="company-text">Company</span>
            <DropDown id="company_selection" v-if="rules && Object.keys(rules).length">
                <template #button>
                    <div class="cursor-pointer text-capitalize dropdown_wrapper pr-22px" :class="{'mr-2' : clientWidth > 1199, 'mr-1' : clientWidth<=1199}">
                        {{selectedCompany && selectedCompany.Cst_CompanyName ? selectedCompany.Cst_CompanyName : "N/A"}}
                    </div>
                </template>
                <template #options>
                    <DropDownOption @click="$emit('change', company._id)" v-for="(company, index) in companies" :key="index" :item="{image: company.Cst_profileImage || defaultUserIcon, label: company.Cst_CompanyName}">
                        <div class="d-flex align-items-center" :class="[{'opacity-5 cursor-default' : company.isDisable === true}]">
                            <span v-if="!company.Cst_profileImage" class="no-image">{{ company.Cst_CompanyName.charAt(0).toUpperCase()}}</span>
                            <template v-else>
                                <img v-if="company.Cst_profileImage?.includes('firebasestorage')" :src="company.Cst_profileImage || defaultUserIcon" alt="company_image" class="profile-image">
                                <WasabiIamgeCompp v-else :companyId="company?._id" :data="{url:company.Cst_profileImage}" class="profile-image"/>
                            </template>
                            <span class="text-capitalize ml-10px">
                                {{company.Cst_CompanyName}}
                            </span>
                        </div>
                    </DropDownOption>
                </template>
            </DropDown>
            </div>
            <div class="position-re" :class="{'mr-2' : clientWidth > 1199, 'pr-1' : clientWidth<=1199}" @click="$router.push({name: 'chats', params: {cid: companyId}})">
                <img src="@/assets/images/svg/chat_icon.svg" class="cursor-pointer" id="chat_driver"/>
                <span v-if="totalMainCounts" class="notification-tick blinking"></span>
            </div>
            <div class="position-re pr-2" v-if="rules && Object.keys(rules).length">
                <img src="@/assets/images/header_notification.png" class="cursor-pointer" id="notification_driver" @click="getNotifications(!notifications.length), showNotification = 0, notificationVisible = true">
                <span :class="{'notification-tick': totalNotification}" class="blinking"></span>
            </div>
            <div class="position-re" :class="{'pr-2' : clientWidth > 1199, 'pr-1' : clientWidth<=1199}" v-if="rules && Object.keys(rules).length">
                <img src="@/assets/images/comment_mention.png" class="cursor-pointer" id="mention_driver" @click="getMentions(!mentions.length), showNotification = 1, notificationVisible = true">
                <span :class="{'notification-tick': totalMentions}" class="blinking"></span>
            </div>
            <div class="position-re" :class="{'pr-2' : clientWidth > 1199, 'pr-1' : clientWidth<=1199}" v-if="rules && Object.keys(rules).length && (companyUser.roleType === 1 || companyUser.roleType === 2)">
                <img src="@/assets/images/svg/tour_image.svg" class="cursor-pointer" id="mention_driver" @click="getTourDetails(),tourVisible = true">
            </div>
            <div :class="{'pr-2' : clientWidth > 1199, 'pr-1' : clientWidth<=1199}">
                <a href="https://help.alianhub.com" target="_blank" class="help__icon">
                    <img src="@/assets/images/questionmark.png" alt="help" class="question__icon">
                </a>
            </div>
            <div id="profile_menu_driver">
            <DropDown class="pr-1" :id="'profile_menu'">
                <template #button>
                    <div class="header-profile-wrapper" ref="profile_menu_dd">
                        <UserProfile
                            :showDot="false"
                            :data="{
                                image: getUser(userId).Employee_profileImageURL,
                                title: getUser(userId).Employee_Name
                            }"
                            width="35px"
                            :thumbnail="'35x35'"
                        />
                    </div>
                </template>
                <template #options>
                    <div>
                        <DropDownOption v-if="rules && Object.keys(rules).length" :item="{image: profileIcon, label: 'My Profile'}" @click="$router.push({name: 'My Settings', params: {cid: companyId}}), $refs.profile_menu_dd.click()"/>
                        <DropDownOption v-if="rules && Object.keys(rules).length" :item="{image: settingsIcon, label: 'Settings'}" @click="$router.push({name: 'Setting', params: {cid: companyId}}), $refs.profile_menu_dd.click()"/>
                        
                        <DropDownOption :item="{image: logoutIcon, label: 'Logout'}" @click="logOut()" />
                    </div>
                </template>
            </DropDown>
            </div>
            <span class="app-version">
                v{{version}}
            </span>
        </div>

        <Sidebar
            title="Test"
            v-model:visible="visible"
            :left="true"
            className="z-index-6 mobile-header-sidebar"
            width="325px !important"
            top="0px"
        >
            <template #head>
                <div class="header-mobile-title">
                    <div class="logo-cross d-flex align-items-center justify-content-between w-100">
                    <router-link to="/">
                        <img class="cursor-pointer" :src="logoImage"/>
                    </router-link>
                    <img :src="closeBlueImageMobile" alt="closeButton" class="cursor-pointer close-btn-mobileheader" @click="visible = !visible"/>
                    </div>
                </div>
            </template>
            <template #body>
                <div class="px-1 d-flex flex-column  main-mobileheader-sidebar h-100 style-scroll">
                    <!-- TOP SECTION -->
                    <div v-if="rules && Object.keys(rules).length">
                        <div class="bg-white p-1 d-flex mobile-profileimg-namewrapper pb-30px">
                            <!-- <UserProfile
                                :showDot="false"
                                :data="{
                                    image: getUser(userId).Employee_profileImageURL,
                                    title: getUser(userId).Employee_Name
                                }"
                                width="45px"
                                style="margin: 0px !important;"
                                ref="profile_menu_dd"
                                class="profile-lg-square"
                            /> -->
                            <img v-if="!getUser(userId).Employee_profileImageURL" :src="getUser(userId).Employee_profileImageURL" alt="user_profile" class="profile-lg-square">
                            <WasabiIamgeCompp v-else :userImage="true" :thumbnail="'35x35'" :data="{title:getUser(userId).Employee_Name, url: getUser(userId).Employee_profileImageURL}" class="profile-lg-square"/>
                            <div class="d-flex flex-column cursor-pointer mobile-degignation-wrapper" :class="{'pl-1': clientWidth > 990, 'pt-20': clientWidth < 990 }">
                                <span class="font-weight-500 text-ellipsis font-size-20 mw-75 color90 pb-4px">{{getUser(userId).Employee_Name}}</span>
                                <span class="text-ellipsis mw-65">Designation</span>
                            </div>
                            </div>

                        <!-- COMPANY SELECTION -->
                        <div class="p-1 cursor-pointer border-radius-10-px m0px-20px bg-light-gray2"  @click="visible = false,$emit('filter')">
                            <span class="gray">Search</span>
                        </div>
                        <DropDown id="company_selection_2" class="company_selection_2"> 
                            <template #button>
                                <div class=" hover-bg-purplemobielist p-1 cursor-pointer border-radius-7-px text-capitalize mobile-menu-list" :class="{'hover-white:hover' : clientWidth<=991}">
                                    {{selectedCompany && selectedCompany.Cst_CompanyName ? selectedCompany.Cst_CompanyName : "N/A"}}
                                </div>
                            </template>

                            <template #head>
                                <div class="hover-bg-purplemobielist d-flex justify-content-center font-weight-bold text-capitalize" v-if="clientWidth<=767">
                                    {{selectedCompany && selectedCompany.Cst_CompanyName ? selectedCompany.Cst_CompanyName : "N/A"}}
                                </div>
                            </template>
                            <template #options>
                                <DropDownOption @click="$emit('change', company._id)" v-for="(company, index) in companies" :key="index" :item="{image: company.Cst_profileImage || defaultUserIcon, label: company.Cst_CompanyName}">
                                    <div class="d-flex align-items-center">
                                        <!-- <img :src="company.Cst_profileImage || defaultUserIcon" alt="company_image" class="profile-image"> -->
                                        <WasabiIamgeCompp :companyId="company.id" :data="{ url: company.Cst_profileImage || defaultUserIcon}" alt="company_image" class="profile-image"/>
                                        <span class="text-capitalize ml-10px">
                                            {{company.Cst_CompanyName}}
                                        </span>
                                    </div>
                                </DropDownOption>
                            </template>
                        </DropDown>
                        <template v-for="(item, index) in menu">
                            <!-- DIRECT LINK -->
                            <div :key="'item'+index" :class="{'active-list-mobile' : $route.name.includes('Project')}" class="hover-bg-purplemobielist hover-white p-1 cursor-pointer border-radius-7-px mobile-menu-list" v-if="item.submenu && !item.submenu.length" @click="$router.push(item.to), visible = false">
                                {{ item.name }}
                            </div>

                            <!-- FOR SUB MENU -->
                            <div :key="'nested-item-'+index" v-else>
                                <DropDown :id="'nav_menu'+index" :title="item.name">
                                    <template #button>
                                        <div :class="{'active-list-mobile': item.name==='Time Sheet' ? $route.name.toLowerCase().includes('timesheet') : $route.name.toLowerCase().includes('report')}" class="hover-bg-purplemobielist hover-white p-1 cursor-pointer border-radius-7-px mobile-menu-list">
                                            {{ item.name }}
                                        </div>
                                    </template>
                                    <template #options>
                                        <DropDownOption v-for="(subItem, subIndex) in item.submenu" :key="subIndex" :style="`${subIndex === item.submenu.length - 1 ? 'margin-bottom:0px !important' : ''}`" @click="$router.push(subItem.to), visible = false">
                                            {{ subItem.name }}
                                        </DropDownOption>
                                    </template>
                                </DropDown>
                            </div>
                        </template>
                        <div  v-if="rules && Object.keys(rules).length" class="hover-bg-purplemobielist hover-white p-1 cursor-pointer border-radius-7-px mobile-menu-list" @click="$router.push({name: 'chats', params: {cid: companyId}})">
                            Chat
                        </div>
                        <div  v-if="rules && Object.keys(rules).length" class="hover-bg-purplemobielist hover-white p-1 cursor-pointer border-radius-7-px mobile-menu-list" @click="getNotifications(!notifications.length), showNotification = 0, notificationVisible = true, visible = false">
                            Notification
                        </div>
                        <div v-if="rules && Object.keys(rules).length" class="hover-bg-purplemobielist hover-white p-1 cursor-pointer border-radius-7-px mobile-menu-list" @click="getMentions(!mentions.length), showNotification = 1, notificationVisible = true, visible = false">
                            @Mentions
                        </div>
                        <div v-if="rules && Object.keys(rules).length && (companyUser.roleType === 1 || companyUser.roleType === 2)" class="hover-bg-purplemobielist hover-white p-1 cursor-pointer border-radius-7-px mobile-menu-list" @click="getTourDetails(),tourVisible = true,visible = false">
                            Tours
                        </div>
                    </div>
                    <!-- BOTTOM SECTION -->
                    <div>
                        <div class="cursor-pointer border-radius-7-px d-flex align-items-center log-out-list font-size-18 font-weight-500 blue" @click="logOut()">
                            <img :src="logoutIconMobile" alt="logout" class="pr-10px">
                            Logout
                        </div>
                    </div>
                </div>
            </template>
        </Sidebar>

        <Sidebar width="550px" :title="!showNotification ? 'Notifications' : 'Mentions'" v-model:visible="notificationVisible">
            <template #head>
                <div class="d-flex align-items-center justify-space-between assignee-headtitle text-capitalize">
                    {{!showNotification ? 'Notifications' : 'Mentions'}}
                </div>
                <div class="d-flex align-items-center justify-space-between">
                    <button v-if="!showNotification ? totalNotification > 0 : totalMentions > 0" class="outline-primary mr-10px" @click="markAllRead(!showNotification ? 'notifications' : 'mentions')">Mark all as read</button>
                    <img :src="closeBlueImage" alt="closeButton" class="cursor-pointer" @click="notificationVisible = false"/>
                </div>
            </template>
            <template #body>
                <div class="overflow-y-auto style-scroll mh-100">
                    <template v-if="(!showNotification ? notifications : mentions).length">
                        <div v-for="item in (!showNotification ? notifications : mentions)" :key="item.id" class="border-bottom notification" :class="{'bg-light-gray': !item.seen, 'bg-white': item.seen}">
                            <div class="d-flex p-1 position-re cursor-pointer" @click="markRead(item, !showNotification ? 'notifications' : 'mentions')">
                                <img v-if="!showNotification" @click.stop="markRead(item, !showNotification ? 'notifications' : 'mentions', false), notifications.length < 1 ? getNotifications(true) : ''" :src="notificationClose" alt="notificationClose" class="position-ab d-none notification-close-icon">

                                <UserProfile :showDot="false" :data="{
                                        image: getUser(item['userId']).Employee_profileImageURL,
                                        title: getUser(item['userId']).Employee_Name
                                    }"
                                    width="45px"
                                    :thumbnail="'40x40'"
                                />
                                <!-- <img :src="getUser(item[!showNotification ? 'UserId' : 'userId']).Employee_profileImageURL" :alt="getUser(item[!showNotification ? 'UserId' : 'userId']).Employee_Name" class="profile-image"> -->

                                <div class="d-flex ml-1 flex-column comment__notification-message">
                                    <strong>{{getUser(item['userId']).Employee_Name}}</strong>
                                    <div v-html="!showNotification ? item.message : `<p>${changeText(item.comment_message)}</p>`"></div>

                                    <span class="">{{ getDateAndTime(new Date(item?.createdAt).getTime()) }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="(!showNotification ? !noNotifications : !noMentions)" class="text-center cursor-pointer blue py-10px" @click="!showNotification ? getNotifications(true) : getMentions(true)">
                            <span>Load more</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center cursor-pointer red py-10px">
                            <span>No data found</span>
                        </div>
                    </template>
                </div>
            </template>
        </Sidebar>
        <Sidebar width="550px" :title="'Take a Tour'" v-model:visible="tourVisible">
            <template #head>
                <div class="d-flex align-items-center justify-space-between assignee-headtitle text-capitalize">
                    Take a Tour
                </div>
                <div class="d-flex align-items-center justify-space-between">
                    <img :src="closeBlueImage" alt="closeButton" class="cursor-pointer" @click="tourVisible = false"/>
                </div>
            </template>
            <template #body>
                <div class="overflow-y-auto style-scroll mh-100">
                    <template v-if="tourList.length">
                        <div v-for="item in tourList" :key="item.id" class="border-bottom notification" :class="{'bg-light-gray': item.isCompleted, 'bg-white': !item.isCompleted}">
                            <div class="d-flex p-1 position-re align-items-center">

                                <UserProfile :showDot="false" :data="{
                                        image: item.image,
                                        title: item.title,
                                    }"
                                    width="40px"
                                    :thumbnail="'40x40'"
                                />

                                <div class="d-flex ml-1 flex-column comment__notification-message">
                                    <strong class="tour__title font-weight-700 black font-size-16">{{item.title}}</strong>
                                    <div class="tour__description font-weight-500 font-size-14 GunPowder">{{item.description}}</div>
                                </div>
                                <div class="text-center">
                                    <span>
                                        <img class="cursor-pointer" :src="!item.isCompleted ? tourComplete : tourRemain" alt="notificationClose" @click="handleTourOps(item)">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center cursor-pointer red py-10px">
                            <span>No data found</span>
                        </div>
                    </template>
                </div>
            </template>
        </Sidebar>
    </div>
</template>

<script setup>
// PACKAGE
import { computed, defineComponent, defineEmits, inject, onMounted, ref, watchEffect } from "vue";
import { App } from "realm-web";
import {version} from "../../../../../package.json";
import { useRouter  } from 'vue-router'
import {useHelper} from "./helper"
import { dbCollections } from '@/utils/FirebaseCollections';
import { useMainChat } from "@/views/Chat/helper";
import { useStore } from "vuex";
import { useCustomComposable, useGetterFunctions } from "@/composable/index.js";
import { mongodbCrudOperations } from '@/utils/MongoQueries/crudOperations/crudOperations';
import { BSON } from 'realm-web';

// COMPONENTS
import NavLinks from "@/components/organisms/NavLinks/NavLinks.vue";
import WasabiIamgeCompp from "@/components/atom/WasabiIamgeCompp/WasabiIamgeCompp.vue"
import Sidebar from "@/components/molecules/Sidebar/Sidebar.vue";
import DropDown from "@/components/molecules/DropDown/DropDown.vue";
import DropDownOption from "@/components/molecules/DropDownOption/DropDownOption.vue";
import UserProfile from "@/components/atom/UserProfile/UserProfile.vue"
import { useProjects } from '@/composable/projects';

// INTERFACES
const companyId = inject("$companyId");
const {getters} = useStore();
const userId = inject("$userId");
const {getUser} = useGetterFunctions()
const {changeText} = useCustomComposable();
const clientWidth = inject("$clientWidth");
const {openRoute, menu} = useHelper();
const {getProjects} = useMainChat();
const {getDateAndTime} = useProjects();

// IMAGES
const closeBlueImage = require("@/assets/images/svg/CloseSidebar.svg");
const closeBlueImageMobile = require("@/assets/images/svg/cross_mobilesidebar_icon.svg");
const profileIcon = require("@/assets/images/svg/User_icon.svg");
const settingsIcon = require("@/assets/images/svg/Setting_icon.svg");
// 
const logoutIcon = require("@/assets/images/svg/Logout_icon.svg");
const logoImage = require("@/assets/images/svg/mobile_site_Logo.svg");
const logoutIconMobile = require("@/assets/images/svg/Mobile_icon/logout.svg");
const defaultUserIcon = inject("$defaultUserAvatar");
const toggle = require("@/assets/images/svg/mobile_toggle_white.svg");
const headerLogo = "/api/v1/getlogo?key=logo&type=web";
const notificationClose = require("@/assets/images/Shape 509.png");
const tourComplete = require("@/assets/images/svg/tourcomplete.svg");
const tourRemain = require("@/assets/images/svg/tourremain.svg");
const app = new App({ id: process.env.VUE_APP_MONGO_APP_ID });

// COMPONENT
defineComponent({
    name: "Header-Component",

    components: {
        NavLinks,
        Sidebar,
        DropDown,
        DropDownOption,
        UserProfile
    }
})

defineEmits(["change"])

onMounted(() => {

    if(getters['mainChat/mainChatProjects'] && !getters['mainChat/mainChatProjects']?.data?.length) {
        getProjects()
        .catch((error) => {
            console.error("ERROR in get projects chat:", error);
        })
    }
})

// DATA
const visible = ref(false);
// 
const showNotification = ref(0);
const notificationVisible = ref(false);
const tourVisible = ref(false);
const myCounts = computed(() => getters["users/myCounts"]?.data || {})
const totalNotification = computed(() => myCounts.value?.notification_counts);
const totalMentions = computed(() => myCounts.value?.mention_counts)
const companyUser = ref(getters['settings/companyUserDetail']);

const dispatchEventForFilet = () => {
    var ctrlKEv = new KeyboardEvent('keydown', {
        bubbles: true,
        cancelable: true,
        key: 'k',
        ctrlKey: true
    });
    document.dispatchEvent(ctrlKEv);
}

function processSprint(obj = {}, pid) {
    let total = 0;
    Object.values(obj || {}).forEach((sprint) => {
        if(myCounts.value?.[`sprint_${pid}_${sprint.id}_comments`]) {
            total += myCounts.value?.[`sprint_${pid}_${sprint.id}_comments`] || 0;
        }
    })

    return total;
}
const totalMainCounts = computed(() => {
    let total = 0;

    getters["mainChat/mainChatProjects"]?.data?.forEach((x) => {
        total += processSprint(x.sprintsObj || {}, x._id)

        Object.values(x.sprintsfolders || {}).forEach((folder) => {
            total += processSprint(folder.sprintsObj || {}, x._id)
        })
    })

    return total;
})
const batchSize = ref(10)
// NOTIFICATION
const notifications = ref([]);
const noNotifications = ref(false);
const notificationSkip = ref(0);

// MENTIONS
const mentions = ref([]);
const noMentions = ref(false);
const firstMention = ref("");
const lastMention = ref("");
const companies = computed(() => {
    return getters["settings/companies"];
})

//Tour List
// const tourList = ref([
//     {
//         "title":"Project Tour",
//         "description":"Look how to create project, manage tasks, view Time Sheet reports, enter a chat, view mentions and notifications, and more.",
//         "image":"https://picsum.photos/201/301",
//         "userId":"65bc7f88d454ec98010f26ec",
//         "isCompleted":false,
//         "id": "isProjectAndNavbarTour"
//     },
//     {
//         "title":"Dashboard",
//         "description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean leo leo, facilisis nec tellus nec, molestie auctor ante.",
//         "image":"https://picsum.photos/200/300",
//         "userId":"65bc7f88d454ec98010f26ec",
//         "isCompleted":false,
//         "id": "isDashboardTour"
//     },
//     {
//         "title":"Time Tracker",
//         "description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean leo leo, facilisis nec tellus nec, molestie auctor ante.",
//         "image":"https://picsum.photos/202/302",
//         "userId":"65bc7f88d454ec98010f26ec",
//         "isCompleted":false,
//         "id": "isTimeTracker"
//     },
//     {
//         "title":"Task Tour",
//         "description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean leo leo, facilisis nec tellus nec, molestie auctor ante.",
//         "image":"https://picsum.photos/203/303",
//         "userId":"65bc7f88d454ec98010f26ec",
//         "isCompleted":false,
//         "id": "isTaskTour"
//     },
// ]);
const tourList = ref([]);
const selectedCompany = ref("");
const chargeBeePriceData = computed(() => {
    return getters["settings/chargeBeePrice"];
})
const companyOwner = computed(() => {
    return getters["settings/companyOwnerDetail"];
})
watchEffect(() => {
    selectedCompany.value = companies.value.find((company) => company._id === companyId.value)
    if (selectedCompany.value && selectedCompany.value.SubcriptionId && selectedCompany.value.SubcriptionId !== '' && chargeBeePriceData.value.length && companyOwner.value.isCurrentUser)  {
        // 
    }
    // 
})

const rules = computed(() => {
    return getters['settings/rules']
})

const router = useRouter()

// 

const deleteToken = () => {
    return new Promise((resolve, reject) => {
        try {

            let token = localStorage.getItem("webTokens");
            if (!userId.value || !token) {
                console.error("UserId or Token is missing");
                resolve(true);
                return;
            }

            // Assuming getUser is synchronous or this part is already handled
            let user = getUser(userId.value);
            let updateOps = {
                $set: {
                    isOnline: false,
                    lastActive: new Date()
                }
            };

            if (user && user.WebTokens && user.WebTokens.includes(token)) {
                updateOps.$pull = { webTokens: token };
            }

            const query = {
                type: "updateOne",
                collection: dbCollections.USERS,
                global: true,
                data: [
                    { _id: BSON.ObjectID(userId.value) },
                    updateOps,
                    true,
                    false
                ]
            };

            const userRef = mongodbCrudOperations(query)
            userRef.then(() => {
                resolve(true)
            })
        } catch (error) {
            reject(false)
            console.error(error,"error");
        }
    })
}

const logOut = (route = null) => {
    deleteToken().then(async ()=>{
        localStorage.removeItem("isLogging");
        localStorage.removeItem("currentUserEmail");
        localStorage.removeItem("SubmenuScreen");
        localStorage.removeItem("selectedCompany");
        localStorage.removeItem("SubmenuScreen");
        localStorage.removeItem("currentLoggedInUserDetails");
        localStorage.removeItem("userId");
        localStorage.removeItem("webTokens");
        localStorage.removeItem("token");
        localStorage.removeItem("updateToken");
        try {
            await app.currentUser.logOut();
            if(route === null) {
                router.push({ name: "Log-in" });
            } else {
                router.push(route)
            }
        } catch (error) {
            console.error(error,"error");
        }
    })
}

function getNotifications(loadMore = false) {
    if (loadMore) {
        if (notifications?.value?.length > 0) {
            notificationSkip.value = notifications?.value?.length
            batchSize.value  = notifications?.value?.length + 10
        }
    }

    let query = [
        {
            $match: {
                $and: [
                    {assigneeUsers:{$in: [ userId.value ]}},
                    {
                        $or: [
                            {notificationType: 'push'},
                            {notificationType: null}
                        ]
                    },
                    {receiverID : userId.value}
                ]
            }
        },
        {$sort: {createdAt: -1, _id: 1}},
        {$limit: batchSize.value},
        {$skip: loadMore ? notificationSkip.value : 0},
    ]

    const data = {
        type: 'aggregate',
        collection: dbCollections.NOTIFICATIONS,
        data: [query]
    }

    mongodbCrudOperations(data).then(async (res) => {

        if (res.length <= 0) {
            if (loadMore) {
                noNotifications.value = true;
            }
            return;
        }

        let uniqueNotifications = res
            .map((x) => ({
                ...x,
                seen: x.notSeen === undefined || !x.notSeen.includes(userId.value),
            }))
            .filter(newNotif => !notifications.value.some(existingNotif => existingNotif._id === newNotif._id));

        if (loadMore) {
            notifications.value = [...notifications.value, ...uniqueNotifications];
        } else {
            notifications.value = [...uniqueNotifications, ...notifications.value];
        }
    })
    .catch((error) => {
        console.error("Error getting new notification:", error);
    });
}

async function getMentions(loadMore = false) {

    let mentionQuery = {
        mentionIds: {
            $in: [userId.value]
        }
    }

    if (loadMore) {
        if (mentions?.value?.length > 0) {
            mentionQuery.createdAt = { $lt: new Date(lastMention.value.createdAt) }
        }
    } else {
        if (mentions?.value?.length > 0) {
            mentionQuery.createdAt = { $gt: new Date(firstMention.value.createdAt) }
        }
    }

    const data = {
        type: 'find',
        collection: dbCollections.MENTIONS,
        data: [mentionQuery, { limit: 10, sort: {createdAt: -1} }]
    }

    mongodbCrudOperations(data).then(async (res) => {
        var mentionsData = res
        if (mentionsData.length <= 0) {
            if (loadMore) {
                noMentions.value = true;
            }
            return;
        }

        if (loadMore) {
                lastMention.value = mentionsData[mentionsData.length - 1];
        }

        if (!loadMore || !lastMention.value.length) {
            firstMention.value = mentionsData[0];
            if (mentionsData.isEmpty) {
                noMentions.value = true;
            }
        }


        let tmp = mentionsData.map((x) => ({
            ...x,
            id: x.id,
            seen: x.notSeen === undefined || !x.notSeen.includes(userId.value)
        }));

        if (loadMore) {
            mentions.value = [...mentions.value, ...tmp];
        } else {
            mentions.value = [...tmp, ...mentions.value];
        }
    })
    .catch((error) => {
        console.error("Error getting new mentions:", error);
    });
}

function markRead(data, key, redirect = true) {
    if(data.notSeen && !data.notSeen.includes(userId.value)) {
        if(redirect) {
            openRoute(data, key,{gettersVal: getters});
            notificationVisible.value = false;
            data.seen = true;
        } else {
            notifications.value = notifications.value.filter((x) => x._id !== data._id);
        }
        return;
    }

    const query = {
        type: 'updateOne',
    }

    if(key === 'notifications') {
        query.collection = `${dbCollections.NOTIFICATIONS}`;
        query.data = [
            {_id: BSON.ObjectId(data._id)},
            {$set: 
                {
                    notificationStatus: 'completed',
                    notSeen: [],
                    isSeen: true
                }
            },
            true, //Upsert
            false
        ]
    } else {
        query.collection = `${dbCollections.MENTIONS}`;
        query.data = [
            {_id: BSON.ObjectId(data._id)},
            {$pull: {notSeen: userId.value}},
            true, //Upsert
            false
        ]
    }
    mongodbCrudOperations(query)
    .then(() => {
        if(redirect) {
            openRoute(data, key,{gettersVal: getters});
            notificationVisible.value = false;
            data.seen = true;
        } else {
            notifications.value = notifications.value.filter((x) => x._id !== data._id);
        }

        if(key === 'notifications' ? totalNotification.value <= 0 : totalMentions.value <= 0) return;
        const queryObj = {
            ...query,
            type : 'deleteOne',
            data : [{notificationId: data._id}],
            global: true
        }

        mongodbCrudOperations(queryObj)
        .catch((error) => {
            console.error(`ERROR in deleting ${key} from global: `, error);
        })
    })
    .catch((error) => {
        console.error(`ERROR in mark ${key} seen: `, error);
    })
}

function markAllRead(key) {

    const query = {
        type: 'update'
    }

    if(key === 'notifications') {
        query.collection = `${dbCollections.NOTIFICATIONS}`;
        query.data = [
            {
                assigneeUsers: {$in: [userId.value]},
                notSeen: {$ne: []},
                isSeen: false,
                notificationStatus: {$ne: 'completed'},
                notificationType: 'push',
            },
            {$set: 
                {
                    notificationStatus: 'completed',
                    notSeen: [],
                    isSeen: true
                }
            },
            true, //Upsert
            false
        ]

    } else {
        query.collection = `${dbCollections.MENTIONS}`;
        query.data = [
            {
                mentionIds: {$in: [userId.value]},
                notSeen: {$nin: [userId.value]}
            },
            {
                $pull: {notSeen: userId.value}
            },
            true, //Upsert
            false
        ]
    }

    mongodbCrudOperations(query)
    .then(() => {
        notifications.value.filter((x) => !x.seen).forEach((data) => data.seen = true);
        mentions.value.filter((x) => !x.seen).forEach((data) => data.seen = true);
    })
    .catch((error) => {
        console.error(`ERROR in update ${key} info: `, error);
    })

    let updateObj = {
        type: 'updateOne',
        collection: dbCollections.USER_ID
    }
    if(key === 'notifications') {
        updateObj.data = [
            {userId: userId.value},
            {notification_counts: 0},
            true, //Upsert
            false
        ]
    } else {
        updateObj.data = [
            {userId: userId.value},
            {mention_counts: 0},
            true, //Upsert
            false
        ]
    }
    
    mongodbCrudOperations(updateObj)
    .catch((error) => {
        console.error(`ERROR in update ${key} seen info: `, error);
        return;
    })
}

function getTourDetails() {
    try {
        let obj = {
            type: "find",
            collection: dbCollections.TOURS,
            data:[{}],
            global: true,
        }
        mongodbCrudOperations(obj).then((res)=>{
            if(res.length <= 0) return;
            tourList.value = res;
            let tourStatus = getUser(userId.value).tourStatus;

            for (let tour of tourList.value) {
                if (Object.hasOwnProperty.call(tourStatus, tour.id)) {
                    tour.isCompleted = tourStatus[tour.id];
                }
            }
        }).catch((error)=>{
            console.error("Error GET TOURS GET",error)
        })
        // tourList.value.sort((b,a)=>{return a.isCompleted - b.isCompleted});
    } catch (error) {
        console.error(`ERROR in getTourDetails`, error);
    }
}

function handleTourOps(tourObject) {
    try {
        let tourUpdateObject = {
            ...(getUser(userId.value).tourStatus && getUser(userId.value).tourStatus),
            [tourObject.id]: !JSON.parse(JSON.stringify(tourObject)).isCompleted
        }
        const query = {
            type: "updateOne",
            collection: dbCollections.USERS,
            global: true,
            data: [
                { _id: BSON.ObjectID(userId.value)},
                { $set: { tour: tourUpdateObject } },
                { upsert: true }
            ]
        };
        mongodbCrudOperations(query).then(()=>{
            let find = tourList.value.findIndex((e)=>e.id == tourObject.id);
            if(find !== -1) {
                tourList.value[find] = {...tourList.value[find],...tourObject,'isCompleted': !tourObject.isCompleted};
            }
        }).catch((e)=>{
            console.error(e);
        })
    } catch (error) {
        console.error(`ERROR in handleTourOps`, error);
    }
}
</script>

<style>
@import './style.css';
</style>